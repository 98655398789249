<template>
  <div class="error-page">
    <div class="circle" @click="handleError()">
      <svg
        class="svg"
        width="850"
        height="850"
        viewBox="0 0 850 850"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M367.49 433.51V401.08H375.356V426.403H386.051V413.293H393.917V426.403H400.61V433.51H393.917V448H386.051V433.51H367.49ZM424.489 448.828C412.69 448.828 406.48 440.41 406.48 424.54C406.48 408.67 412.69 400.252 424.489 400.252C436.288 400.252 442.498 408.67 442.498 424.54C442.498 440.41 436.288 448.828 424.489 448.828ZM414.001 424.54C414.001 436.201 417.451 441.928 424.489 441.928C431.527 441.928 434.977 436.201 434.977 424.54C434.977 412.879 431.527 407.152 424.489 407.152C417.451 407.152 414.001 412.879 414.001 424.54ZM449.765 433.51V401.08H457.631V426.403H468.326V413.293H476.192V426.403H482.885V433.51H476.192V448H468.326V433.51H449.765Z" fill="white" />
        <g class="circle">
          <path d="M134.687 346.002C132.652 353.523 127.352 356.724 120.062 354.752L121.507 349.412C125.732 350.555 128.939 348.831 130.032 344.792C130.986 341.263 129.889 338.873 126.963 338.082C124.503 337.416 122.935 338.238 120.457 341.504L116.889 346.22C114.205 349.878 110.702 351.223 106.57 350.105C101.277 348.673 98.8106 343.422 100.469 337.293C102.215 330.839 107.41 327.66 113.539 329.318L112.094 334.658C108.519 333.69 105.948 335.088 104.969 338.709C104.14 341.774 105.241 343.965 107.702 344.631C109.931 345.234 111.843 344.057 114.406 340.664L117.649 336.41C120.426 332.776 123.858 331.512 128.269 332.705C133.84 334.212 136.433 339.548 134.687 346.002Z" fill="white" />
          <path d="M138.425 330.67L117.19 323.494L118.853 318.573L140.088 325.748L138.425 330.67ZM110.03 322.446C107.98 321.753 106.847 319.695 107.586 317.508C108.325 315.32 110.474 314.371 112.525 315.064C114.575 315.757 115.708 317.815 114.969 320.002C114.229 322.189 112.081 323.139 110.03 322.446Z" fill="white" />
          <path d="M145.58 311.918C144.654 314.293 142.627 318.562 135.592 315.817L125.868 312.024L124.487 315.564L120.409 313.973L121.79 310.433L115.472 307.968L117.377 303.084L123.695 305.549L125.566 300.754L129.644 302.345L127.773 307.14L136.377 310.496C140.007 311.912 140.892 310.967 141.643 309.04C141.941 308.278 142.323 307.033 142.426 306.505L146.728 308.183C146.565 308.998 146.14 310.484 145.58 311.918Z" fill="white" />
          <path d="M153.389 294.965C150.347 301.348 144.383 303.355 137.826 300.231C131.356 297.149 129.091 291.168 132.028 285.001C134.78 279.226 140.55 277.18 146.238 279.89C147.063 280.283 147.694 280.637 148.782 281.262L141.624 296.286C144.899 297.687 147.629 296.43 149.263 293C150.38 290.655 150.304 288.913 149.113 287.387L151.389 282.61C154.877 285.018 155.706 290.102 153.389 294.965ZM138.019 294.569L143.026 284.061C139.879 282.614 137.273 283.611 135.721 286.867C134.128 290.211 135.174 293.16 138.019 294.569Z" fill="white" />
          <path d="M145.748 239.173L148.549 234.798L176.904 252.955L174.103 257.33L171.551 255.696C172.702 258.089 172.177 261.408 170.698 263.717C166.807 269.793 160.384 270.192 154.834 266.638C149.285 263.085 147.166 256.759 150.927 250.886C152.536 248.374 155.122 246.832 157.778 246.877L145.748 239.173ZM157.661 262.223C161.104 264.428 164.979 263.996 167.184 260.553C169.596 256.786 168.009 253.199 164.768 251.124C161.528 249.049 157.606 249.108 155.194 252.875C152.989 256.318 154.218 260.018 157.661 262.223Z" fill="white" />
          <path d="M187.52 239.878C182.961 245.716 176.143 246.006 170.949 241.951C165.756 237.895 164.385 231.21 168.945 225.371C173.504 219.533 180.322 219.243 185.515 223.299C190.709 227.355 192.079 234.04 187.52 239.878ZM174.176 237.818C177.399 240.335 181.238 240.342 183.843 237.006C186.448 233.67 185.511 229.948 182.288 227.431C179.066 224.915 175.227 224.907 172.622 228.243C170.016 231.579 170.954 235.302 174.176 237.818Z" fill="white" />
          <path d="M204.411 220.087C199.601 225.27 193.309 225.402 187.986 220.461C182.733 215.586 182.359 209.201 187.005 204.195C191.357 199.506 197.475 199.278 202.093 203.564C202.763 204.186 203.259 204.712 204.111 205.633L192.789 217.831C195.496 220.147 198.477 219.764 201.062 216.979C202.829 215.075 203.277 213.39 202.596 211.578L206.196 207.7C208.805 211.04 208.076 216.138 204.411 220.087ZM189.863 215.116L197.781 206.584C195.21 204.263 192.426 204.435 189.972 207.079C187.452 209.794 187.569 212.921 189.863 215.116Z" fill="white" />
          <path d="M220.182 204.359C216.167 208.094 211.047 208.521 208.131 205.386L211.97 201.815C213.28 203.224 215.571 202.933 217.544 201.098C218.988 199.755 219.183 198.129 218.102 196.966C217.25 196.051 216.338 195.847 214.151 197.028L210.057 199.194C207.178 200.689 204.335 200.246 202.173 197.921C199.585 195.139 200.327 190.639 203.989 187.232C208.005 183.498 212.272 183.142 215.22 186.311L211.451 189.817C210.174 188.443 208.364 188.616 206.568 190.286C205.194 191.564 204.863 193.186 205.78 194.172C206.599 195.052 207.684 195.16 209.592 194.174L213.752 192.078C216.699 190.585 219.751 190.9 221.913 193.224C224.894 196.429 224.162 200.657 220.182 204.359Z" fill="white" />
          <path d="M239.358 187.084L226.173 168.957L230.413 165.873L232.252 168.402C231.965 165.636 233.302 163.058 235.986 161.106C240.343 157.937 244.381 158.747 247.833 163.493L256.123 174.89L251.883 177.974L245.008 168.521C242.207 164.67 239.979 163.733 237.022 165.884C234.261 167.893 234.035 170.853 236.355 174.042L243.598 184L239.358 187.084Z" fill="white" />
          <path d="M270.134 166.871C263.787 170.69 257.317 168.52 253.92 162.873C250.523 157.227 251.636 150.494 257.984 146.675C264.331 142.857 270.801 145.027 274.198 150.673C277.595 156.32 276.481 163.052 270.134 166.871ZM258.412 160.17C260.52 163.674 264.102 165.055 267.729 162.873C271.356 160.691 271.813 156.879 269.705 153.376C267.598 149.873 264.016 148.491 260.389 150.673C256.762 152.855 256.305 156.667 258.412 160.17Z" fill="white" />
          <path d="M292.222 154.483C289.948 155.635 285.591 157.465 282.178 150.728L277.461 141.417L274.071 143.134L272.093 139.23L275.483 137.513L272.418 131.463L277.095 129.093L280.16 135.143L284.751 132.817L286.729 136.722L282.138 139.048L286.311 147.286C288.072 150.762 289.362 150.647 291.207 149.713C291.937 149.343 293.052 148.671 293.48 148.346L295.567 152.465C294.903 152.963 293.595 153.787 292.222 154.483Z" fill="white" />
          <path d="M320.057 142.81C313.438 145.297 307.751 142.604 305.196 135.805C302.675 129.096 305.194 123.217 311.588 120.814C317.576 118.564 323.151 121.094 325.367 126.993C325.689 127.848 325.898 128.541 326.247 129.745L310.668 135.599C312.055 138.88 314.893 139.869 318.45 138.532C320.881 137.619 322.035 136.312 322.236 134.387L327.189 132.526C328.031 136.68 325.1 140.915 320.057 142.81ZM309.264 131.861L320.16 127.767C318.897 124.542 316.33 123.452 312.953 124.721C309.485 126.023 308.192 128.873 309.264 131.861Z" fill="white" />
          <path d="M331.506 138.004L336.305 124.844L325.403 116.436L331.64 114.628L338.144 120.305L340.602 112.03L346.839 110.221L342.126 123.157L353.219 131.709L346.982 133.517L340.273 127.65L337.743 136.196L331.506 138.004Z" fill="white" />
          <path d="M356.89 131.081L351.934 109.222L357 108.073L361.956 129.933L356.89 131.081ZM349.094 102.566C348.615 100.455 349.78 98.4156 352.032 97.9051C354.283 97.3946 356.214 98.7323 356.693 100.843C357.171 102.954 356.006 104.994 353.755 105.504C351.503 106.015 349.572 104.677 349.094 102.566Z" fill="white" />
          <path d="M375.606 127.93C370.199 128.842 365.687 126.384 364.975 122.162L370.145 121.29C370.465 123.188 372.539 124.204 375.195 123.756C377.14 123.428 378.197 122.176 377.933 120.611C377.725 119.378 377.075 118.707 374.6 118.491L369.989 118.049C366.762 117.715 364.631 115.782 364.103 112.651C363.471 108.904 366.564 105.553 371.497 104.721C376.904 103.81 380.664 105.858 381.384 110.127L376.309 110.983C375.997 109.133 374.39 108.282 371.971 108.69C370.121 109.002 368.953 110.175 369.177 111.503C369.377 112.688 370.225 113.375 372.36 113.6L376.987 114.137C380.27 114.51 382.646 116.451 383.174 119.581C383.901 123.897 380.966 127.026 375.606 127.93Z" fill="white" />
          <path d="M397.582 124.775C395.047 125.038 390.323 125.189 389.545 117.677L388.469 107.295L384.689 107.687L384.238 103.333L388.017 102.941L387.318 96.1955L392.533 95.6549L393.232 102.401L398.352 101.87L398.803 106.224L393.684 106.755L394.636 115.941C395.037 119.816 396.283 120.17 398.34 119.957C399.154 119.873 400.436 119.643 400.952 119.493L401.428 124.086C400.63 124.314 399.113 124.616 397.582 124.775Z" fill="white" />
          <path d="M435.666 114.011C432.59 113.908 430.173 111.325 430.276 108.248C430.38 105.124 432.963 102.707 436.04 102.81C439.164 102.914 441.581 105.497 441.477 108.622C441.374 111.698 438.791 114.115 435.666 114.011Z" fill="white" />
          <path d="M471.299 126.675L477.559 93.5919L488.618 95.6846C495.235 96.9366 498.644 100.911 497.624 106.298C496.775 110.788 493.897 112.544 489.926 112.576C493.455 113.587 496.15 116.936 495.256 121.662C494.174 127.381 489.542 130.127 482.973 128.884L471.299 126.675ZM477.637 122.93L483.781 124.093C487.042 124.71 489.329 123.233 489.928 120.067C490.456 117.278 488.734 114.994 485.662 114.413L479.471 113.241L477.637 122.93ZM480.374 108.468L485.762 109.487C489.164 110.131 491.519 108.814 492.055 105.979C492.601 103.096 490.929 101.066 487.479 100.413L482.091 99.3938L480.374 108.468Z" fill="white" />
          <path d="M506.532 135.061C501.811 133.726 499.311 130.019 500.948 124.233L504.785 110.673L509.83 112.1L506.346 124.411C505.364 127.883 505.898 130.233 509.185 131.163C513.026 132.25 515.135 130.098 516.012 126.997L519.457 114.824L524.502 116.252L518.398 137.82L513.354 136.392L514.205 133.384C512.254 135.331 509.633 135.939 506.532 135.061Z" fill="white" />
          <path d="M532.08 143.098C529.685 142.227 525.37 140.299 527.952 133.202L531.52 123.393L527.949 122.094L529.446 117.981L533.017 119.28L535.335 112.906L540.262 114.699L537.944 121.072L542.78 122.832L541.284 126.945L536.447 125.186L533.29 133.864C531.958 137.526 532.923 138.389 534.867 139.096C535.636 139.376 536.889 139.729 537.419 139.82L535.84 144.159C535.023 144.015 533.527 143.624 532.08 143.098Z" fill="white" />
          <path d="M574.756 137.27L579.455 139.597L569.464 159.77C567.906 162.916 565.783 164.817 563.291 165.623C560.906 166.32 558.173 165.986 555.328 164.577C552.354 163.104 550.389 161.219 549.456 159.093C548.653 157.138 548.752 154.987 549.798 152.767L554.539 155.115C553.666 157.42 554.146 159.161 557.163 160.655C559.965 162.043 562.823 161.365 564.937 157.098L566.196 154.555C563.879 156.091 561.061 156.145 558.087 154.672C553.13 152.217 552.423 147.465 554.814 142.638L560.877 130.396L565.575 132.723L560.004 143.973C558.531 146.947 558.412 149.68 561.774 151.345C565.05 152.967 567.904 151.107 569.612 147.658L574.756 137.27Z" fill="white" />
          <path d="M583.251 168.533C576.939 164.657 575.886 157.914 579.335 152.299C582.783 146.684 589.273 144.573 595.585 148.449C601.897 152.325 602.949 159.068 599.501 164.683C596.052 170.298 589.563 172.409 583.251 168.533ZM583.802 155.043C581.663 158.527 582.085 162.342 585.692 164.557C589.299 166.772 592.894 165.423 595.033 161.939C597.173 158.455 596.75 154.64 593.143 152.425C589.536 150.21 585.942 151.559 583.802 155.043Z" fill="white" />
          <path d="M603.165 181.909C599.221 178.991 598.192 174.64 601.768 169.806L610.15 158.477L614.365 161.595L606.755 171.881C604.609 174.781 604.279 177.169 607.024 179.2C610.234 181.574 612.967 180.306 614.884 177.715L622.408 167.545L626.622 170.663L613.291 188.683L609.077 185.565L610.936 183.051C608.423 184.184 605.756 183.826 603.165 181.909Z" fill="white" />
          <path d="M633.295 207.148C628.098 202.144 628.965 195.766 633.536 191.019C638.107 186.273 644.724 185.433 649.748 190.271C651.896 192.34 652.902 195.179 652.339 197.775L654.441 195.592L658.183 199.195L642.635 215.341L638.893 211.737L640.995 209.554C638.422 210.215 635.27 209.05 633.295 207.148ZM637.312 194.656C634.476 197.601 634.141 201.485 637.086 204.321C640.308 207.424 644.136 206.57 646.805 203.798C649.475 201.026 650.184 197.169 646.962 194.066C644.017 191.23 640.148 191.711 637.312 194.656Z" fill="white" />
          <path d="M646.616 219.798L663.305 204.835L666.805 208.739L663.582 211.629C666.492 210.118 669.364 211.161 671.002 212.987C671.547 213.596 671.961 214.129 672.239 214.656L668.658 217.866C668.184 217.193 667.895 216.871 667.349 216.262C664.876 213.505 661.29 213.684 657.494 217.087L650.116 223.702L646.616 219.798Z" fill="white" />
          <path d="M663.446 240.605C659.102 235.026 659.98 228.795 665.71 224.332C671.365 219.929 677.727 220.584 681.924 225.973C685.854 231.02 685.098 237.096 680.126 240.967C679.405 241.528 678.807 241.934 677.761 242.626L667.536 229.495C664.816 231.796 664.716 234.799 667.051 237.798C668.647 239.847 670.238 240.559 672.136 240.178L675.387 244.353C671.671 246.393 666.755 244.856 663.446 240.605ZM670.686 227.042L677.837 236.226C680.54 234.061 680.817 231.285 678.601 228.438C676.325 225.516 673.22 225.13 670.686 227.042Z" fill="white" />
          <path d="M684.433 271.453C680.637 265.092 682.831 258.63 688.49 255.254C694.149 251.877 700.877 253.015 704.673 259.376C708.468 265.737 706.275 272.199 700.616 275.576C694.957 278.953 688.229 277.814 684.433 271.453ZM691.176 259.756C687.665 261.851 686.271 265.428 688.44 269.063C690.609 272.698 694.419 273.169 697.93 271.074C701.441 268.979 702.835 265.402 700.666 261.767C698.497 258.132 694.687 257.661 691.176 259.756Z" fill="white" />
          <path d="M692.983 285.274L713.16 275.51L715.443 280.229L712.629 281.591C715.402 281.799 717.702 283.571 719.148 286.559C721.494 291.408 719.982 295.239 714.7 297.795L702.014 303.934L699.73 299.215L710.251 294.124C714.537 292.049 715.854 290.023 714.262 286.732C712.774 283.658 709.901 282.911 706.351 284.629L695.267 289.993L692.983 285.274Z" fill="white" />
          <path d="M708.768 321.183L729.864 313.608L731.619 318.497L710.524 326.072L708.768 321.183ZM736.125 309.98C738.162 309.249 740.328 310.157 741.109 312.33C741.889 314.503 740.796 316.582 738.758 317.314C736.721 318.045 734.555 317.137 733.775 314.964C732.995 312.791 734.088 310.712 736.125 309.98Z" fill="white" />
          <path d="M714.756 340.112C714.001 337.677 712.927 333.074 720.14 330.838L730.11 327.748L728.985 324.118L733.165 322.822L734.291 326.452L740.769 324.443L742.321 329.451L735.843 331.459L737.367 336.375L733.186 337.671L731.662 332.755L722.841 335.49C719.12 336.644 719.017 337.935 719.629 339.91C719.871 340.691 720.348 341.903 720.596 342.38L716.186 343.747C715.806 343.009 715.212 341.582 714.756 340.112Z" fill="white" />
          <path d="M732.696 375.211C732.195 372.174 734.256 369.299 737.294 368.798C740.379 368.29 743.253 370.351 743.754 373.389C744.262 376.474 742.201 379.348 739.116 379.857C736.079 380.357 733.204 378.296 732.696 375.211Z" fill="white" />
          <path d="M727.494 419.991L761.41 409.089L761.283 414.908L736.631 422.355L760.943 430.44L760.854 434.528L736.212 441.543L760.515 450.06L760.387 455.879L726.98 443.506L727.067 439.515L751.232 432.297L727.407 423.982L727.494 419.991Z" fill="white" />
          <path d="M746.76 472.875C746.005 478.209 742.613 480.546 736.803 479.723L722.849 477.746L723.584 472.555L735.157 474.195C739.872 474.863 742.095 473.915 742.608 470.295C743.087 466.914 741.119 464.692 737.213 464.138L725.022 462.411L725.757 457.22L759.094 461.943L758.359 467.135L744.119 465.117C746.305 466.835 747.226 469.589 746.76 472.875Z" fill="white" />
          <path d="M718.841 493.571C720.509 486.552 726.441 484.056 732.853 485.579C739.264 487.103 743.35 492.375 741.738 499.161C741.048 502.062 739.115 504.372 736.593 505.206L739.541 505.907L738.34 510.961L716.533 505.778L717.734 500.724L720.682 501.425C718.805 499.545 718.207 496.238 718.841 493.571ZM731.64 490.68C727.663 489.735 724.147 491.421 723.202 495.399C722.168 499.751 724.849 502.613 728.593 503.502C732.337 504.392 736.019 503.043 737.054 498.691C737.999 494.713 735.618 491.626 731.64 490.68Z" fill="white" />
          <path d="M711.755 520.005C712.524 517.575 714.268 513.182 721.467 515.461L731.418 518.612L732.565 514.989L736.738 516.31L735.591 519.933L742.057 521.98L740.475 526.978L734.009 524.931L732.456 529.838L728.283 528.517L729.836 523.61L721.031 520.823C717.317 519.647 716.496 520.648 715.871 522.62C715.624 523.4 715.324 524.667 715.256 525.2L710.854 523.806C710.963 522.983 711.29 521.472 711.755 520.005Z" fill="white" />
          <path d="M704.901 539.475L725.59 548.099L723.591 552.894L702.902 544.27L704.901 539.475ZM732.66 549.639C734.658 550.472 735.646 552.604 734.758 554.735C733.869 556.866 731.66 557.665 729.662 556.832C727.664 555.999 726.677 553.868 727.565 551.736C728.454 549.605 730.663 548.807 732.66 549.639Z" fill="white" />
          <path d="M696.725 556.451C699.102 551.51 703.715 549.245 707.572 551.101L705.299 555.826C703.565 554.992 701.538 556.098 700.371 558.526C699.516 560.303 699.927 561.889 701.357 562.577C702.484 563.119 703.407 562.976 705.013 561.08L708.036 557.57C710.172 555.128 712.981 554.504 715.842 555.881C719.266 557.528 720.216 561.988 718.047 566.496C715.67 571.437 711.826 573.325 707.925 571.448L710.156 566.81C711.847 567.623 713.469 566.803 714.533 564.592C715.346 562.902 715.063 561.271 713.849 560.687C712.766 560.166 711.716 560.461 710.299 562.075L707.19 565.543C704.99 568.007 702.033 568.827 699.173 567.45C695.228 565.553 694.368 561.349 696.725 556.451Z" fill="white" />
          <path d="M680.739 585.989C682.073 583.817 684.827 579.976 691.262 583.928L700.156 589.39L702.145 586.152L705.875 588.443L703.886 591.681L709.666 595.23L706.922 599.698L701.143 596.149L698.449 600.534L694.719 598.244L697.413 593.858L689.543 589.025C686.223 586.986 685.184 587.759 684.102 589.522C683.674 590.219 683.076 591.376 682.881 591.877L678.946 589.46C679.251 588.688 679.934 587.301 680.739 585.989Z" fill="white" />
          <path d="M687.507 617.402C684.352 621.768 680.262 622.254 675.506 618.817L664.083 610.563L667.153 606.313L676.627 613.159C680.487 615.948 682.896 616.146 685.037 613.183C687.037 610.415 686.332 607.532 683.135 605.221L673.154 598.009L676.225 593.76L703.515 613.481L700.445 617.73L688.788 609.306C689.92 611.846 689.451 614.712 687.507 617.402Z" fill="white" />
          <path d="M660.269 615.104L677.57 629.355L674.268 633.364L656.966 619.114L660.269 615.104ZM683.891 632.878C685.562 634.254 685.889 636.58 684.421 638.362C682.953 640.145 680.608 640.269 678.937 638.893C677.266 637.517 676.939 635.19 678.407 633.408C679.874 631.626 682.22 631.502 683.891 632.878Z" fill="white" />
          <path d="M648.031 628.435C651.732 624.389 656.801 623.55 659.96 626.439L656.422 630.308C655.002 629.009 652.742 629.485 650.924 631.472C649.594 632.928 649.53 634.565 650.702 635.636C651.625 636.48 652.55 636.609 654.634 635.256L658.54 632.766C661.289 631.043 664.159 631.256 666.501 633.398C669.305 635.963 668.93 640.507 665.554 644.199C661.853 648.245 657.629 648.945 654.434 646.023L657.907 642.225C659.292 643.491 661.082 643.173 662.737 641.363C664.003 639.978 664.202 638.335 663.208 637.426C662.321 636.615 661.231 636.596 659.409 637.732L655.432 640.157C652.615 641.883 649.548 641.816 647.205 639.673C643.975 636.719 644.363 632.446 648.031 628.435Z" fill="white" />
          <path d="M635.952 672.782C631.096 676.947 624.778 676.157 620.174 670.79C615.665 665.533 615.934 658.901 620.862 654.674C623.126 652.732 626.219 651.98 628.687 652.714L620.138 642.747L624.081 639.365L645.251 664.045L641.308 667.427L639.335 665.127C639.685 667.678 638.361 670.715 635.952 672.782ZM634.157 658.796C631.401 655.583 627.478 655.146 624.484 657.714C621.563 660.219 621.429 664.2 624.154 667.376C626.878 670.553 630.833 671.026 633.754 668.521C636.747 665.953 636.913 662.008 634.157 658.796Z" fill="white" />
          <path d="M608.8 665.164L628.999 692.102L624.804 695.247L604.605 668.309L608.8 665.164Z" fill="white" />
          <path d="M592.743 675.933C598.771 671.969 604.8 674.223 608.42 679.729C612.041 685.235 611.398 691.875 605.57 695.706C603.078 697.345 600.087 697.699 597.68 696.576L599.345 699.108L595.004 701.962L582.69 683.233L587.031 680.379L588.696 682.911C588.619 680.256 590.452 677.439 592.743 675.933ZM604.04 682.609C601.793 679.193 598.079 678.008 594.663 680.254C590.925 682.712 590.913 686.634 593.027 689.849C595.141 693.065 598.746 694.609 602.484 692.151C605.901 689.905 606.286 686.025 604.04 682.609Z" fill="white" />
          <path d="M568.339 690.619C574.479 687.309 580.444 689.558 583.754 695.697C587.063 701.837 585.662 708.056 579.522 711.366C574.018 714.333 568.968 713.175 566.102 708.873L570.718 706.385C572.435 708.355 574.514 708.765 577.308 707.259C580.569 705.501 581.307 702.207 579.139 698.185C576.97 694.163 573.813 692.968 570.553 694.726C567.758 696.232 566.935 698.151 567.545 700.5L562.93 702.987C560.98 698.356 562.835 693.586 568.339 690.619Z" fill="white" />
          <path d="M546.678 701.224C553.151 698.378 558.978 700.755 561.901 707.404C564.786 713.965 562.593 719.973 556.341 722.722C550.485 725.297 544.779 723.077 542.243 717.309C541.875 716.472 541.629 715.792 541.214 714.609L556.449 707.91C554.884 704.71 551.996 703.878 548.518 705.407C546.14 706.453 545.06 707.821 544.964 709.754L540.121 711.884C539.053 707.782 541.747 703.392 546.678 701.224ZM558.056 711.565L547.4 716.25C548.838 719.401 551.462 720.349 554.764 718.897C558.154 717.406 559.289 714.49 558.056 711.565Z" fill="white" />
          <path d="M533.015 719.532C533.971 722.255 534.256 723.94 532.308 726.815L530.761 729.09C529.274 731.243 528.937 733.044 529.733 735.313C530.672 737.991 532.986 739.065 535.482 738.19C538.523 737.123 540.142 734.618 538.789 730.76L544.009 728.929C546.285 735.419 543.474 740.483 536.848 742.808C530.856 744.91 526.194 742.518 524.395 737.389C523.026 733.486 524.197 730.577 526.202 727.427L527.39 725.583C528.578 723.739 528.625 722.856 528.116 721.404L527.893 720.768L532.84 719.033L533.015 719.532ZM531.877 711.929C532.61 714.016 531.535 716.33 529.402 717.079C527.269 717.827 524.939 716.707 524.206 714.619C523.474 712.532 524.578 710.156 526.711 709.408C528.844 708.66 531.145 709.841 531.877 711.929Z" fill="white" />
          <path d="M483.848 731.268C486.871 730.688 489.799 732.673 490.379 735.697C490.967 738.767 488.982 741.695 485.959 742.275C482.888 742.863 479.961 740.878 479.372 737.807C478.792 734.784 480.777 731.857 483.848 731.268Z" fill="white" />
          <path d="M440.86 727.273L452.791 760.842L446.971 760.892L438.775 736.479L431.435 761.026L427.347 761.061L419.584 736.645L411.811 761.195L405.991 761.246L417.34 727.476L421.332 727.442L429.283 751.376L436.868 727.308L440.86 727.273Z" fill="white" />
          <path d="M388.593 748.275C383.238 747.683 380.798 744.365 381.442 738.532L382.989 724.524L388.2 725.1L386.917 736.717C386.395 741.451 387.411 743.644 391.044 744.045C394.439 744.42 396.599 742.384 397.032 738.463L398.383 726.224L403.595 726.8L399.899 760.266L394.688 759.691L396.267 745.396C394.616 747.633 391.892 748.639 388.593 748.275Z" fill="white" />
          <path d="M368.431 721.36C375.504 722.784 378.205 728.626 376.905 735.086C375.605 741.546 370.477 745.813 363.64 744.437C360.716 743.849 358.341 741.997 357.42 739.506L356.822 742.477L351.729 741.452L356.151 719.478L361.244 720.503L360.646 723.473C362.46 721.532 365.743 720.819 368.431 721.36ZM371.765 734.052C372.571 730.044 370.764 726.589 366.756 725.782C362.371 724.9 359.604 727.679 358.844 731.452C358.085 735.224 359.562 738.858 363.947 739.74C367.955 740.547 370.958 738.06 371.765 734.052Z" fill="white" />
          <path d="M340.272 714.846C342.725 715.54 347.169 717.147 345.112 724.414L342.27 734.457L345.927 735.492L344.735 739.703L341.078 738.669L339.232 745.195L334.187 743.767L336.034 737.241L331.081 735.84L332.273 731.628L337.226 733.029L339.74 724.143C340.801 720.394 339.775 719.604 337.785 719.041C336.998 718.818 335.722 718.557 335.187 718.506L336.444 714.062C337.27 714.146 338.791 714.427 340.272 714.846Z" fill="white" />
          <path d="M292.101 733.564L287.298 731.584L300.134 700.457L304.937 702.437L303.781 705.239C305.933 703.68 309.292 703.609 311.827 704.654C318.497 707.405 320.029 713.656 317.517 719.748C315.005 725.84 309.156 729.047 302.708 726.388C299.951 725.251 297.974 722.979 297.547 720.358L292.101 733.564ZM312.67 717.749C314.229 713.969 313.116 710.233 309.337 708.674C305.201 706.969 301.953 709.167 300.486 712.725C299.019 716.282 299.773 720.131 303.909 721.836C307.689 723.395 311.112 721.529 312.67 717.749Z" fill="white" />
          <path d="M285.152 692.302C291.714 695.74 293.222 702.395 290.164 708.232C287.105 714.069 280.775 716.616 274.213 713.178C267.652 709.74 266.144 703.085 269.202 697.248C272.26 691.411 278.591 688.864 285.152 692.302ZM285.52 705.798C287.417 702.177 286.736 698.399 282.987 696.434C279.238 694.47 275.744 696.06 273.846 699.682C271.948 703.303 272.63 707.081 276.379 709.046C280.128 711.01 283.622 709.42 285.52 705.798Z" fill="white" />
          <path d="M263.884 680.024C269.853 683.814 271.124 689.977 267.23 696.109C263.388 702.159 257.177 703.685 251.412 700.023C246.011 696.594 244.677 690.618 248.055 685.299C248.545 684.528 248.973 683.944 249.724 682.94L263.773 691.862C265.56 688.78 264.642 685.918 261.434 683.881C259.242 682.489 257.504 682.354 255.845 683.351L251.378 680.515C254.19 677.343 259.336 677.136 263.884 680.024ZM261.633 695.232L251.807 688.992C249.991 691.942 250.665 694.649 253.71 696.583C256.837 698.568 259.891 697.886 261.633 695.232Z" fill="white" />
          <path d="M245.842 667.648C250.238 670.926 251.579 675.886 249.021 679.318L244.817 676.184C245.967 674.642 245.269 672.441 243.109 670.83C241.528 669.652 239.893 669.753 238.944 671.025C238.197 672.028 238.161 672.961 239.715 674.9L242.583 678.538C244.571 681.101 244.647 683.977 242.749 686.522C240.478 689.568 235.918 689.648 231.908 686.658C227.512 683.381 226.394 679.247 228.982 675.777L233.108 678.853C231.986 680.357 232.482 682.106 234.449 683.573C235.952 684.694 237.607 684.728 238.412 683.648C239.131 682.684 239.041 681.597 237.728 679.898L234.918 676.183C232.92 673.553 232.68 670.494 234.578 667.949C237.194 664.44 241.484 664.399 245.842 667.648Z" fill="white" />
          <path d="M224.763 651.285L209.698 667.881L205.852 664.39L220.917 647.793L224.763 651.285ZM205.875 674.025C204.42 675.628 202.081 675.843 200.371 674.291C198.662 672.74 198.65 670.391 200.105 668.788C201.56 667.185 203.899 666.97 205.609 668.522C207.318 670.074 207.33 672.423 205.875 674.025Z" fill="white" />
          <path d="M209.989 636.817C211.793 638.619 214.889 642.189 209.552 647.531L202.175 654.915L204.863 657.601L201.77 660.697L199.081 658.012L194.288 662.81L190.579 659.104L195.372 654.306L191.731 650.669L194.825 647.572L198.466 651.21L204.993 644.676C207.747 641.92 207.236 640.73 205.773 639.268C205.194 638.69 204.208 637.84 203.765 637.534L207.029 634.268C207.709 634.743 208.9 635.729 209.989 636.817Z" fill="white" />
          <path d="M190.906 614.943C195.401 620.587 193.704 626.795 188.549 630.9C183.395 635.006 176.724 634.968 172.379 629.512C170.521 627.18 169.897 624.234 170.796 621.734L168.426 623.622L165.19 619.558L182.723 605.595L185.96 609.658L183.589 611.546C186.227 611.229 189.198 612.798 190.906 614.943ZM185.283 626.799C188.481 624.252 189.324 620.445 186.777 617.247C183.99 613.748 180.083 614.092 177.073 616.49C174.063 618.887 172.853 622.618 175.639 626.117C178.186 629.315 182.085 629.346 185.283 626.799Z" fill="white" />
          <path d="M178.884 600.029L151.23 619.235L148.239 614.929L175.894 595.723L178.884 600.029Z" fill="white" />
          <path d="M172.802 591.119L144.468 609.308L141.635 604.896L169.97 586.707L172.802 591.119Z" fill="white" />
          <path d="M160.196 571.084L139.984 580.774L137.717 576.047L140.537 574.695C138.256 574.722 135.762 573.303 134.494 570.658C132.726 566.971 133.458 564.38 135.037 562.556C132.541 562.579 129.836 560.942 128.463 558.079C125.552 552.007 129.134 548.797 132.907 546.987L145.702 540.853L147.969 545.581L136.084 551.278C132.745 552.88 131.894 554.888 133.079 557.36C134.223 559.746 136.288 561.049 141.102 558.741L151.816 553.605L154.082 558.332L142.155 564.051C138.815 565.652 137.966 567.553 139.151 570.025C140.295 572.41 142.402 573.801 147.216 571.493L157.929 566.356L160.196 571.084Z" fill="white" />
          <path d="M140.956 525.632C143.321 532.296 140.524 537.933 133.68 540.362C126.926 542.76 121.094 540.134 118.809 533.697C116.669 527.668 119.301 522.141 125.239 520.033C126.1 519.727 126.797 519.531 128.007 519.204L133.574 534.888C136.88 533.561 137.921 530.742 136.65 527.161C135.781 524.713 134.496 523.536 132.574 523.299L130.805 518.313C134.973 517.548 139.154 520.555 140.956 525.632ZM129.812 536.223L125.918 525.253C122.67 526.457 121.533 529.004 122.74 532.404C123.978 535.895 126.804 537.24 129.812 536.223Z" fill="white" />
          <path d="M134.034 503.402C135.864 510.381 131.802 515.374 125.427 517.045C119.053 518.715 112.967 515.984 111.199 509.238C110.443 506.353 111.063 503.406 112.896 501.483L109.965 502.252L108.648 497.227L130.33 491.544L131.647 496.569L128.716 497.337C131.256 498.114 133.339 500.75 134.034 503.402ZM124.098 511.973C128.053 510.937 130.363 507.795 129.326 503.84C128.192 499.513 124.479 498.249 120.757 499.224C117.035 500.2 114.42 503.123 115.554 507.45C116.59 511.405 120.143 513.01 124.098 511.973Z" fill="white" />
          <path d="M128.828 484.451L106.712 488.096L105.859 482.923L108.944 482.414C106.344 481.429 104.64 479.077 104.101 475.803C103.225 470.487 105.761 467.242 111.551 466.287L125.457 463.996L126.309 469.169L114.777 471.07C110.078 471.844 108.241 473.414 108.835 477.021C109.391 480.391 111.934 481.922 115.826 481.28L127.975 479.278L128.828 484.451Z" fill="white" />
          <path d="M113.355 455.743C110.479 455.986 108.777 455.841 106.481 453.235L104.665 451.169C102.953 449.189 101.294 448.413 98.8976 448.616C96.0698 448.855 94.4506 450.826 94.6737 453.462C94.9455 456.674 96.9656 458.868 101.04 458.523L101.506 464.035C94.6523 464.615 90.4529 460.626 89.8606 453.628C89.3251 447.302 92.8072 443.387 98.2232 442.928C102.345 442.579 104.868 444.442 107.416 447.17L108.904 448.782C110.392 450.394 111.235 450.661 112.769 450.531L113.44 450.474L113.882 455.698L113.355 455.743ZM121.001 456.544C118.797 456.731 116.825 455.111 116.635 452.859C116.444 450.606 118.111 448.631 120.316 448.444C122.521 448.257 124.544 449.92 124.735 452.173C124.925 454.426 123.206 456.357 121.001 456.544Z" fill="white" />
          <path d="M114.293 407.168C114.108 410.241 111.462 412.588 108.389 412.403C105.268 412.215 102.921 409.568 103.106 406.495C103.294 403.374 105.941 401.028 109.062 401.216C112.135 401.401 114.481 404.048 114.293 407.168Z" fill="white" />
        </g>
      </svg>
    </div>
    <nav class="page-navigation">
      <ControlPanelLight />
    </nav>
  </div>
</template>

<script setup>
import ControlPanelLight from '~/common/ControlPanelLight.vue';
import { statamicLinks, StatamicLinksInjectionKey } from '~/content/statamicLinks';

const props = defineProps({
  error: Object
});

const links = await statamicLinks();
provide(StatamicLinksInjectionKey, links);

function handleError () {
  clearError({ redirect: '/' });
}

</script>

<style scoped lang="scss">

.error-page {
  --color-background: #1c1c1c;
  --color-font: #ffffff;
  --gradient-red: var(--gradient-red--dark);
  --gradient-green: var(--gradient-green--dark);

  --control-panel--color-background: #282828;
  --control-panel--color-font: #ffffff;
  --control-panel--color-background-icons: #282828;
  --control-panel--color-background-secondary: #474747;

  --deep-dives--color-background: #282828;
  --deep-dives--color-font: #ffffff;

  color: #ffffff;
  height: var(--app-height);

  > .circle {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;

    &::after {
      content: "";
      width: 80vmax;
      height: 80vmax;
      position: fixed;
      inset: auto auto -25vmax -25vmax;
      background-image: url("/images/gradient-simple-blue.webp");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: -1;
    }

    &::before {
      content: "";
      width: 80vmax;
      height: 80vmax;
      position: fixed;
      inset: -30vmax -30vmax auto auto;
      background-image: url("/images/gradient-simple-yellow.webp");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: -1;
    }

    > .svg {
      height: 100vmin;
      max-height: 100%;
      margin: auto;

      > .circle {
        transform-origin: center;
        animation: rotate 100000ms linear;
        animation-iteration-count: infinite;
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
