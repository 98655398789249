<template>
  <div
    class="control-panel -level-1"
    @mouseleave="mouseLeavesPopup()"
  >
    <div class="content">
      <div
        class="popup control-panel-popup"
        :class="{ '-active': !!currentPopup }"
      >
        <div v-if="currentPopup && currentPopup.id === 'jobs' " class="content -jobs">
          <JobsList />
        </div>
        <div v-if="currentPopup && currentPopup.id === 'imprint'" class="content -imprint">
          <TextImprint class="text" />
        </div>
        <div v-if="currentPopup && currentPopup.id === 'privacy'" class="content -privacy">
          <TextPrivacy class="text" />
        </div>
        <div v-if="currentPopup" class="bottombar">
          <span class="title">{{ currentPopup.text }}</span>
          <CloseX class="button" @click="openPopup(null)" />
        </div>
      </div>
      <div class="bars">
        <div class="bar control-panel-bar -extension">
          <span class="placeholdercolumn" />
          <span class="placeholdercolumn" />
          <div class="column column-button" />
        </div>
        <div class="bar control-panel-bar -first-level">
          <div class="column column-logo">
            <GridonicLogo @click="handleError()" />
          </div>
          <div class="column column-content">
            <div class="internal-links">
              <span
                v-for="iL in internalLink"
                :key="iL.id"
                class="link"
                @click="handleError(iL.link)"
              >
                {{ t(iL.text) }}
              </span>
              <span
                v-for="(pL) in popupLinks"
                :key="`popup-${pL.id}`"
                :class="{ '-only-footer': pL.showInFooter }"
                class="popuplink"
                @click="openPopup(pL.id)"
              >{{ t(pL.text) }}</span>
            </div>
            <div class="social-links">
              <SocialLink
                :tooltip="t('socialLinks.linkedin')"
                class="-hidden-on-mobile"
                link="https://ch.linkedin.com/company/gridonic"
                platform="linkedin"
              />
              <SocialLink
                :tooltip="t('socialLinks.instagram')"
                link="https://www.instagram.com/gridonic.ch/"
                platform="instagram"
              />
              <SocialLink
                :tooltip="t('socialLinks.mail')"
                class="-hidden-on-mobile"
                link="mailto:hello@gridonic.ch"
                platform="mail"
              />
              <SocialLink
                :tooltip="t('socialLinks.videoCall')"
                link="https://calendly.com/gridonic"
                platform="contact"
              />
              <SocialLink
                :tooltip="t('socialLinks.portal')"
                class="-hidden-on-mobile"
                link="https://portal.gridonic.ch/login"
                platform="web"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import GridonicLogo from '~/common/GridonicLogo.vue';
import SocialLink from '~/common/control-panel-components/SocialLink.vue';
import CloseX from '~/common/icons/CloseX.vue';
import TextPrivacy from '~/common/control-panel-components/TextPrivacy.vue';
import TextImprint from '~/common/control-panel-components/TextImprint.vue';
import JobsList from '~/common/control-panel-components/JobsList.vue';

interface PopupLink {
  id: string;
  showInFooter: boolean;
  text: string;
}
const { t } = useI18n();

const currentPopup = ref<PopupLink | null>(null);

const popupLinks = [
  {
    id: 'imprint',
    showInFooter: true,
    text: 'controlPanel.imprint'
  },
  {
    id: 'privacy',
    showInFooter: true,
    text: 'controlPanel.privacy'
  },
  {
    id: 'jobs',
    showInFooter: false,
    text: 'controlPanel.jobs'
  }
];

const internalLink = [
  {
    showInFooter: false,
    link: '/team',
    text: 'Team'
  },
  {
    showInFooter: false,
    link: '/work',
    text: 'Work'
  }
];

// watchEffect(() => {
//   if (!isOnFirstLevel.value) {
//     currentPopup.value = null;
//     setControlPanelExtensionLink(null);
//   }
// });

function openPopup (newPopupId: 'jobs' | 'imprint' | 'privacy' | null) {
  const newPopup = newPopupId ? popupLinks.find(i => i.id === newPopupId) ?? null : null;

  if (newPopup && newPopup !== currentPopup.value) {
    currentPopup.value = newPopup;
  } else {
    currentPopup.value = null;
  }
}

function mouseLeavesPopup () {
  if (currentPopup.value !== null) {
    openPopup(null);
  }
}

function scrollTo (top: number) {
  window.scrollTo({
    top
  });
}

function handleError (redirect = '/') {
  clearError({ redirect: '/' });
}

</script>

<style lang="scss">

@mixin in-footer-view {
  .control-panel.-footer-view & {
    @content;
  }
}

@mixin out-footer-view {
  .control-panel:not(.-footer-view) & {
    @content;
  }
}

@mixin extension-visible {
  .control-panel.-show-extension & {
    @content;
  }
}

@mixin extension-hidden {
  .control-panel:not(.-show-extension) & {
    @content;
  }
}

.control-panel {
  @include fluid(--floating, 15);
  @include fluid(--padding, 10);
  @include fluid(--spacing-base, 10);
  @include fluid(--width, 450);
  @include fluid(--icon-size, 30);
  @include fluid(--extension-width, 55);
  @include fluid(--extension-width-negative, -50);
  @include fluid(--height, 60);
  @include text-xxs;

  --padding-around: 1rem;

  position: fixed;
  inset: auto auto 0 50%;
  transform: translateX(-50%);
  padding: var(--padding-around);
  height: calc(var(--padding-around) + var(--padding-around) + var(--height));
  width: calc(var(--padding-around) + var(--padding-around) + var(--width));
  max-width: 100vw;

  &.-faded-out {
    > .content {
      transform: translateY(10vh);
      transition-duration: 650ms;
    }
  }

  &.-faded-in {
    > .content {
      transform: translateY(0vh);
      transition-duration: 650ms;
    }
  }

  &.-faded-out-immediate {
    > .content {
      transform: translateY(10vh);
      transition-duration: 0ms;
    }
  }

  &.-faded-in-immediate {
    > .content {
      transform: translateY(0vh);
      transition-duration: 0ms;
    }
  }

  > .content {
    width: 100%;
    height: 100%;

    > .popup {
      width: 100%;
      height: fit-content;
      padding: calc(0.5ch + var(--floating));
    }

    > .bars {
      width: 100%;
      height: 100%;
    }
  }
}

.control-panel-popup {
  position: absolute;
  inset: auto 0 0 0;
  transition: clip-path 1250ms;
  background-color: #282828;
  border-radius: 0.75rem;
  min-height: var(--height);

  &.-active {
    z-index: 10;
    clip-path: inset(0 0 0 0);
  }

  &:not(.-active) {
    z-index: 0;
    clip-path: inset(100% 0 0 0);
  }

  > .bottombar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding-top: 1rem;

    > .title {
      border-bottom: 1px solid currentColor;
    }

    > .button {
      cursor: pointer;
    }
  }

  > .content {
    @include hide-scrollbar;
    @include text-xs;

    //padding-bottom: var(--height);
    min-height: var(--height);
    max-height: calc(var(--app-height) * 0.5);
    overflow-y: auto;
    height: fit-content;

    > .text {
      p {
        @include fluid(padding-bottom, 16);

        a {
          color: currentColor;
        }
      }
    }
  }

}

.control-panel-bar {
  width: 100%;
  height: 100%;
  color: var(--control-panel--color-font);
  border-radius: 0.75rem;
  display: grid;
  grid-template-columns: calc(var(--height) * 0.5) minmax(0, auto) calc(var(--height) * 0.5);
  gap: calc(1.5 * var(--padding));
  position: absolute;
  inset: 0;
  padding: var(--padding) var(--floating);

  &.-extension {
    background-color: var(--control-panel--color-background-secondary);
  }

  &.-first-level {
    z-index: 2;
    background-color: #282828;
    transition: width 350ms;

    @include extension-visible {
      width: calc(100% - var(--extension-width));
    }

    @include extension-hidden {
      width: 100%;
    }

    > .column-content {
      grid-column: 2 / 4;
    }
  }

  &.-second-level {
    z-index: 2;
    background-color: var(--control-panel--color-background);
    transition: clip-path 750ms ease-in-out, background-color 350ms, scale 750ms;

    &.-active {
      clip-path: inset(0 0 0 0);
      scale: 1.01;
    }

    &:not(.-active) {
      clip-path: inset(0 100% 0 0);
      scale: 1;
    }
  }

  > .column {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > .column-button {
    justify-content: flex-end;

    .button,
    .icon {
      cursor: pointer;
    }
  }
}

.internal-links {
  display: flex;
  align-items: center;
  gap: var(--padding);

  > .link,
  > .popuplink {
    color: var(--control-panel--color-font);
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;

    &::before {
      content: "";
      position: absolute;
      width: 200%;
      height: 200%;
      top: -50%;
      left: -50%;
      z-index: -1;
      background-image: radial-gradient(rgba(218, 66, 221, 0) 5%, transparent 70%);
    }

    &:hover {
      &::before {
        background-image: radial-gradient(rgba(218, 66, 221, 0.15) 5%, transparent 60%);
      }
    }

    &.-only-footer {
      @include in-footer-view {
        display: block;
      }
      @include out-footer-view {
        display: none;
      }
    }

    &:not(.-only-footer) {
      @include in-footer-view {
        display: none;
      }
      @include out-footer-view {
        display: block;
      }
    }
  }
}

.social-links {
  display: flex;
  align-items: center;
  gap: var(--padding);
  color: var(--control-panel--color-font);

  @media (--sm) {
    @include extension-visible {
      display: none;
    }
  }

}

.floating-icon {
  animation: floating-icon 2000ms infinite;

  &:hover {
    animation-play-state: paused;
  }

  @keyframes floating-icon {
    0% {
      transform: translateX(0.25rem);
    }
    50% {
      transform: translateX(-0.25rem);
    }
    100% {
      transform: translateX(0.25rem);
    }
  }
}

</style>
